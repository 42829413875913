import React from 'react';
import Helmet from 'react-helmet';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import ItemsList from '../components/ItemsList';
import AuthorSocial from '../components/AuthorSocial';

export const AuthorBioTemplate = ({ content, contentComponent, author }) => {
  const AuthorContent = contentComponent || Content;

  return (
    <div className="author-page">
      <section className="section-author">
        <div className="section-author-inner grid-container">
          <div className="featured-thumbnail col-md-4 col-xs-12">
            {author.featuredimage ? (
              <PreviewCompatibleImage
                imageInfo={{
                  image: author.featuredimage.src,
                  alt: author.name,
                }}
              />
            ) : (
              <PreviewCompatibleImage
                imageInfo={{
                  image: '/img/author-placeholder.jpg',
                  alt: author.name,
                }}
              />
            )}
          </div>
          <div className="section-author-bio col-md-8 col-xs-12">
            <div className="author-head-container">
              <div className="section-author-bio-title">
                <h1 className="section-author-bio-name">{author.name}</h1>
                <span className="author-position">{author.position}</span>
              </div>
              <AuthorSocial
                name={author.name}
                github={author.github}
                twitter={author.twitter}
                linkedin={author.linkedin}
              />
            </div>
            <div className="summary">
              <AuthorContent content={content} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

class AuthorComponent extends React.Component {
  render() {
    const posts = this.props.data.posts.edges;
    const events = this.props.data.events.edges;
    const author = this.props.pageContext.author;
    const title = this.props.data.site.siteMetadata.title;
    const totalPosts = this.props.data.posts.totalCount;
    const authorPostsHeader = `${totalPosts} post${
      totalPosts === 1 ? '' : 's'
    } by ${author}`;
    const authorEventsHeader = `${author} conferences`;
    const slug = author.toLowerCase().replace(' ', '-').normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\u0142/g, "l");
    const location = `${process.env.PAGE_DOMAIN}/authors/${slug}/`;

    const authorData = this.props.data.author || {};
    const isAuthorData = authorData && authorData.frontmatter;

    return (
      <Layout pageType={authorData.html ? "DetailsPage" : "HomePage"}>
        <div className={isAuthorData ? "main" : "main author-data-absent"}>
          {isAuthorData ? (
            <AuthorBioTemplate
              content={authorData.html}
              contentComponent={HTMLContent}
              author={authorData.frontmatter}
              summary={<Helmet title={`${author} | ${title}`} />}
            />
          ) : null}
            <section className="section-summary">
              <Helmet title={`${author} | ${title}`} />
              <GatsbySeo
                canonical={location}
                openGraph={{
                  title: `${author} | ${title}`,
                  url: `${location}`,
                }}
              />
            </section>
            {posts.length > 0 && (
              <section className="category-section grid-container author-article-list-container">
                <div className="container col-sm-10">
                  <h2 className="section-title">
                    {authorPostsHeader}
                  </h2>
                  <div className="content">
                    <ItemsList initialItems={posts} />
                  </div>
                </div>
              </section>
            )}
            {events.length > 0 && (
              <section className="category-section grid-container author-article-list-container">
                <div className="container col-sm-10">
                  <h3 className="section-title">
                    {authorEventsHeader}
                  </h3>
                  <div className="content">
                    <ItemsList initialItems={events} />
                  </div>
                </div>
              </section>
            )}
        </div>
      </Layout>
    );
  }
}

export default AuthorComponent;

export const authorPageQuery = graphql`
  query AuthorPage($author: String) {
    site {
      siteMetadata {
        title
      }
    }
    author: markdownRemark(frontmatter: { name: { eq: $author } }) {
      id
      html
      frontmatter {
        name
        position
        twitter
        github
        linkedin
      }
      ...featuredImageAuthor
    }
    posts: allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { authors: { eq: $author } } }
    ) {
      totalCount
      edges {
        node {
          ...BlogPostCardFragment
        }
      }
    }
    events: allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { speakers: { eq: $author } } }
    ) {
      totalCount
      edges {
        node {
          ...EventCardFragment
        }
      }
    }
  }
`;
